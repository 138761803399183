import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_LegalNotice = _resolveComponent("LegalNotice")!
  const _component_Debug = _resolveComponent("Debug")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _createVNode(_component_Notification),
    _createVNode(_component_LegalNotice),
    (_ctx.debug)
      ? (_openBlock(), _createBlock(_component_Debug, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}