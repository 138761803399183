import { APIResult } from './api';

const zusammenarbeit = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchZusammenarbeit(context: any, id: number) {
      return context
        .dispatch('API', { url: `zusammenarbeit/${id}` })
        .then((res: APIResult) => res.data.message)
        .catch(() => []);
    },
    updateZusammenarbeit(context: any, payload: { id: number; data: Array<{ userid: number; text: string; note: string }> }) {
      return context
        .dispatch('API', { url: `zusammenarbeit/${payload.id}`, method: 'patch', data: payload.data })
        .then((res: APIResult) => res.data.message)
        .catch(() => []);
    },
  },
};

export default zusammenarbeit;
