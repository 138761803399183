
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    value: { type: Number, required: true },
  },
  setup(props) {
    const store = useStore();
    const clazz = computed(() => store.getters.getRating(props.value));
    return { clazz };
  },
});
