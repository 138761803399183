
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    type: { type: String, default: 'text' },
    label: { type: String, default: '' },
    modelValue: String,
  },
  emits: ['focus', 'update:modelValue'],
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });

    const onChange = (value: string) => {
      model.value = value;
    };

    return {
      model,
      onChange,
      emit,
    };
  },
});
