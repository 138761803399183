import { AxiosResponse } from 'axios';
import store from '.';

type TrainerRequestBody = {
  name: string;
  ort: string;
  schwerpunkt: string;
  referenz: string;
  feedback: string;
  tagsatz: string;
  tagsatzcomment: string;
  url: string;
};

const trainer = {
  state: {},
  mutations: {},
  actions: {
    fetchTrainers(context: any) {
      return context.dispatch('API', { method: 'get', url: 'trainer' });
    },
    deleteTrainer(context: any, id: number) {
      return context.dispatch('API', { method: 'delete', url: `trainer/${id}` });
    },
    updateTrainer(context: any, payload: { id: number; data: TrainerRequestBody }) {
      return context.dispatch('API', { method: 'patch', url: `trainer/${payload.id}`, data: payload.data });
    },
    addtrainer(context: any, payload: TrainerRequestBody) {
      return context.dispatch('API', { method: 'post', url: `trainer`, data: payload });
    },
  },
  getters: {},
};

export default trainer;
