
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const notifications = computed(() => store.state.notifications.notifications);
    store.dispatch('clearStore');

    return {
      notifications,
    };
  },
});
