import { AxiosResponse } from 'axios';

const feedback = {
  state: {},
  mutations: {},
  actions: {
    startFeedback(context: any) {
      return context.dispatch('API', { url: 'feedback/start', method: 'post' }).then((res: AxiosResponse) => {
        return res.data.message;
      });
    },
    stopFeedback(context: any) {
      return context.dispatch('API', { url: 'feedback/stop', method: 'post' }).then((res: AxiosResponse) => {
        return res.data.message;
      });
    },
    saveSkillMatrix(context: any, payload: { id: number; skillmatrix: any }) {
      return context
        .dispatch('API', { method: 'patch', url: `feedback/${payload.id}/skillmatrix`, data: payload.skillmatrix })
        .then((res: AxiosResponse) => {
          return Promise.resolve(res.data.message);
        });
    },

    fetchFeedbackMeta(context: any) {
      return context.dispatch('API', { url: 'feedback/meta' }).then((res: AxiosResponse) => {
        return res.data.message;
      });
    },
    fetchFeedbackers(context: any, id: number) {
      return context.dispatch('API', { url: `feedback/${id}/feedbacker` });
    },
    fetchOpenFeedbacks(context: any) {
      return context.dispatch('API', { url: 'feedback/open' });
    },
    saveFeedback(context: any, payload: { id: number; grades: any }) {
      return context.dispatch('API', { url: `feedback/${payload.id}/grade`, data: payload.grades, method: 'post' });
    },
  },
  getters: {},
};

export default feedback;
