
import { defineComponent, inject, ref } from 'vue';
import { mdiPlus } from '@mdi/js';
import { Emitter } from 'mitt';

export default defineComponent({
  props: {
    skills: { type: Object, required: true },
    title: { type: String, required: true },
    type: { type: String, default: 'personal' },
    unit: { type: Number },
    group: { type: String, default: 'personal' },
    name: { type: String, default: '' },
  },
  setup(props) {
    const id = '_' + Math.random().toString(36).slice(-2);
    const isOpen = ref(localStorage.getItem(`${props.group}-tableopen`) == 'open' || false);
    const mitt = inject('emitter') as Emitter<{ open: string }>;

    const headerClick = () => {
      isOpen.value = !isOpen.value;
      localStorage.setItem(`${props.group}-tableopen`, isOpen.value ? 'open' : 'closed');
    };

    mitt.on('open', (group: string) => {
      if (group == props.group) {
        isOpen.value = true;
        localStorage.setItem(`${props.group}-tableopen`, isOpen.value ? 'open' : 'closed');
      }
    });

    return {
      id,
      isOpen,
      headerClick,
      mdiPlus,
    };
  },
});
