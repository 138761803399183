
import { mdiClose } from '@mdi/js';
import { computed, defineComponent, PropType, ref } from 'vue';

type AutoCompleteObject = {
  label: string;
  value: any;
};

export default defineComponent({
  props: {
    modelValue: { type: Object as PropType<Array<any>>, required: true },
    values: { type: Object as PropType<Array<AutoCompleteObject>>, required: true },
  },
  setup(props, { emit }) {
    const filter = ref<string>('');
    const textbox = ref<HTMLElement>();
    const focus = ref(false);
    const model = computed({
      get: () => props.modelValue,
      set: (id: Array<any>) => emit('update:modelValue', id),
    });

    const isSelected = (searchItem: AutoCompleteObject) => {
      if (undefined == model.value.find((item: AutoCompleteObject) => item.value == searchItem.value && item.label == searchItem.label)) return false;
      return true;
    };

    const filtered = computed(() => {
      return props.values.filter(
        (item: AutoCompleteObject) => filter.value != '' && item.label.toLowerCase().includes(filter.value.toLowerCase()) && !isSelected(item)
      );
    });

    const onFocus = () => {
      focus.value = true;
      textbox.value?.focus();
    };

    const addToList = (item: AutoCompleteObject) => {
      if (!isSelected(item)) {
        model.value.push(item);
        filter.value = '';
      }
    };

    const removeFromList = (item: AutoCompleteObject) => {
      model.value = model.value.filter((searchItem: AutoCompleteObject) => searchItem.value != item.value && searchItem.label != item.label);
    };

    return {
      model,
      filter,
      filtered,
      focus,
      onFocus,
      textbox,
      addToList,
      removeFromList,
      mdiClose,
    };
  },
});
