
import { defineComponent, PropType, ref } from 'vue';
import { mdiInformationOutline } from '@mdi/js';

export default defineComponent({
  props: {
    text: { type: String },
    small: { type: Boolean, default: false },
    blank: { type: Boolean, default: false },
    position: { type: String as PropType<'left' | 'right' | 'center'>, default: 'left' },
  },
  setup() {
    const active = ref(false);

    const onActivate = () => {
      active.value = true;
    };

    const onDeactivate = () => {
      active.value = false;
    };
    return {
      active,
      onActivate,
      onDeactivate,
      mdiInformationOutline,
    };
  },
});
