
import { defineComponent } from 'vue';
import { mdiStar, mdiDiamond, mdiCrown } from '@mdi/js';

export default defineComponent({
  props: {
    withTarget: { type: Boolean, default: false },
    noheader: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
  },
  setup() {
    return {
      mdiStar,
      mdiCrown,
      mdiDiamond,
    };
  },
});
