import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "notification-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_TransitionGroup, { name: "notificationlist" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["notification", notification.type]),
            key: index
          }, _toDisplayString(notification.message), 3))
        }), 128))
      ]),
      _: 1
    })
  ]))
}