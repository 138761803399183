
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const count = ref(0);
    const countUsers = ref(0);
    const showMine = ref(false);

    store.dispatch('fetchOpenFeedbacks').then((res: any) => {
      if (res.data.message) {
        count.value = parseInt(res.data.message.count);
        countUsers.value = res.data.message.users.length;
        showMine.value = Object.keys(res.data.message.mine).length > 0;
      }
    });

    return {
      count,
      countUsers,
      showMine,
    };
  },
});
