
import { defineComponent, ref } from 'vue';
import LegalNotice from '@/components/LegalNotice.vue';
export default defineComponent({
  components: {
    LegalNotice,
  },
  setup() {
    const debug = ref(false);

    document.body.addEventListener('keyup', function (event) {
      if (event.ctrlKey && event.key == 'F3') {
        debug.value = !debug.value;
      }
    });

    return { debug };
  },
});
