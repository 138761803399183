export const messages = {
  de: {
    login: {
      username: 'BENUTZERNAME',
      password: 'PASSWORT',
      login: 'JETZT EINLOGGEN',
      acceptinvite: 'Einladung annehmen',
    },
    app: {
      overview: 'ÜBERSICHT',
      stammdatenmanagement: 'STAMMDATEN-VERWALTUNG',
      gradingmanagement: 'BENOTUNGS-VERWALTUNG',
      employees: 'MITARBEITER:INNEN',
      loading: 'Daten werden geladen...',
      feedbackrounds: 'Evaluierungsrunden',
    },
    filter: {
      search: 'Filter',
      selected: 'Ausgewählte Abteilungen',
      start: 'Vergleich starten',
      reset: 'Zurücksetzen',
    },
    skills: {
      personal: 'Persönliche Skills',
      social: 'Soziale Skills',
      professional: 'Fachliche Skills',
    },
    legend: {
      title: 'Legende SKILL BASE',
      high: 'Hohe Kompetenz',
      medium: 'Mittlere Kompetenz',
      low: 'Niedrige Kompetenz',
      none: 'Keine Wertung',
      future: 'Future Skills',
      strategic: 'Overall Skills',
      historic: 'Historischer Wert',
      target: 'Zielwert',
      leadership: 'Leadership Skills',
    },
    management: {
      units: {
        users: 'Mitarbeiter:innen die dieser Abteilung zugeordnet waren, verlieren ihre Zuweisung.',
        skills: 'Skills die dieser Abteilung zugewiesen wurden, verlieren ihre Zuweisung.',
      },
      usersadmin: {
        grades: 'Wenn Sie diese:n Mitarbeiter:in löschen gehen jegliche Bewertungen verloren.',
      },
      grades: {
        from: 'Feedbackgeber:in',
        to: 'Feedbackempfänger:in',
        in: '... in dieser Evaluierungsrunde.',
        state: 'Status',
        done: 'abgegeben',
        open: 'offen',
        modal: 'Feedback löschen?',
        confirm: 'Diese Änderung kann nicht wieder rückgänging gemacht werden.',
      },
      skills: {
        grades: 'Wenn Sie diesen Skill löschen, gehen alle getroffenen Einschätzungen, für diesen Skill verloren.',
        units: 'Wenn Sie diesen Skill löschen, gehen alle Abteilungszuweisen verloren.',
        delete: 'Löschen',
        new: 'Neuen Skill hinzufügen',
        name: 'Name',
        type: 'Typ',
        desc: 'Beschreibung',
        addto: 'Skill den Folgenden Abteilungen zuweisen:',
      },
      feedbacks: {
        stop: 'Evaluierungrunde stoppen',
        start: 'Evaluierungsrunde starten',
        current: 'Momentan ist eine Evaluierungsrunde im Gange',
        start_text: 'Start',
        end_text: 'Ende',
        running: 'laufend',
        finished: 'abgeschlossen',
      },
      createunit: 'Neue Abteilung hinzufügen',
      deleteunit_title: '{unit} löschen?',
      deleteconfirm: 'Wirklich löschen',
      users: 'Benutzer:innen',
      create: 'User Anlegen',
      new: 'Neue:n Mitarbeiter:in anlegen',
      title: 'Stammdaten ändern',
      name: 'Name',
      login: 'Login',
      unit: 'Abteilung',
      email: 'Email',
      role: 'Rolle',
      password_title: 'Passwort hinterlegen',
      password_title2: 'Passwort ändern',
      save: 'Speichern',
      firstname: 'Vorname',
      lastname: 'Nachname',
      plan: 'Entwicklungsplan',
      delete: 'User löschen',
      upload_title: 'Datenupload',
      upload_subtitle: 'Daten hochladen & verwalten',
      upload: 'Jetzt hochladen',
      entertext: 'Text eingeben',
      edit_title: 'Stammdaten {name}',
      edit_subtitle: 'Benutzer:innendaten ändern oder anpassen',
      feedback_title: '360-Grad-Feedback',
      feedback_subtitle: 'Folgende Mitarbeiter:innen evaluieren {name}',
    },
  },
};
