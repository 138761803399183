import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-257c1464"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "row mt-5"
}
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DegreeBar = _resolveComponent("DegreeBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.feedback)
      ? (_openBlock(), _createBlock(_component_DegreeBar, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.h1 || _ctx.h2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.h1)
              ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.h1), 1))
              : _createCommentVNode("", true),
            (_ctx.h2)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.h2), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}