import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-965522bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "filter"
}
const _hoisted_2 = { class: "items" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["autocompletecontainer", { hasfilter: _ctx.filter != '', focus: _ctx.focus }])
  }, [
    _createElementVNode("div", {
      class: "autocomplete",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: item.label
        }, [
          _createTextVNode(_toDisplayString(item.label) + " ", 1),
          _createVNode(_component_Icon, {
            path: _ctx.mdiClose,
            onClick: ($event: any) => (_ctx.removeFromList(item)),
            width: 15,
            height: 15
          }, null, 8, ["path", "onClick"])
        ]))
      }), 128)),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
        ref: "textbox",
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = false))
      }, null, 544), [
        [_vModelText, _ctx.filter]
      ])
    ]),
    (_ctx.filter != '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtered, (item) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "item",
                key: item.label,
                onClick: ($event: any) => (_ctx.addToList(item))
              }, _toDisplayString(item.label), 9, _hoisted_3))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}