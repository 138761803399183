import { toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "udropdowncontainer" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "label",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args)), ["stop"]))
    }, _toDisplayString(_ctx.label), 1),
    (_ctx.open)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (user) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "button",
              key: user.id,
              onClick: ($event: any) => (_ctx.onSelect(user))
            }, _toDisplayString(user.name), 9, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Icon, {
      path: _ctx.mdiMenuDown,
      width: 15,
      onClick: _withModifiers(_ctx.onClick, ["stop"])
    }, null, 8, ["path", "onClick"])
  ]))
}