
import { defineComponent, inject, onMounted, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { mdiMenuDown } from '@mdi/js';
import { Emitter } from 'mitt';

interface UserRecord {
  id: number;
  username: string;
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  unit_id: number;
  notiz: string;
  files: string;
  hidden: boolean;
  data: any;
}

export default defineComponent({
  props: {
    users: { type: Array as PropType<Array<UserRecord>>, default: () => [] },
    withbackevent: { type: Boolean, default: false },
  },
  emits: ['changed', 'opened', 'closed'],
  setup(props, { emit }) {
    const store = useStore();
    const open = ref(false);
    const label = ref('Details');
    const labels = ref<Array<{ name: string; id: number }>>([]);

    const mitt = inject('emitter') as Emitter<{ closedropdowns: string }>;
    const mitt2 = inject('emitter') as Emitter<{ awayfromdetails: string }>;

    mitt.on('closedropdowns', () => {
      open.value = false;
      emit('closed');
    });

    onMounted(() => {
      if (props.withbackevent) {
        labels.value.push({
          name: `Details`,
          id: -1,
        });
      }

      for (const user of props.users) {
        labels.value.push({
          name: `${user.firstname} ${user.lastname}`,
          id: user.id,
        });
      }
    });

    mitt2.on('awayfromdetails', () => {
      label.value = 'Details';
      open.value = false;
    });

    const onClick = () => {
      if (open.value) {
        mitt.emit('closedropdowns', '');
        open.value = false;
        emit('closed');
      } else {
        mitt.emit('closedropdowns', '');
        open.value = true;
        emit('opened');
      }
    };

    const onSelect = (user: { name: string; id: number }) => {
      mitt.emit('closedropdowns', '');
      emit('changed', user.id);
      label.value = user.name;
    };
    return {
      open,
      label,
      mdiMenuDown,
      onClick,
      onSelect,
      labels,
    };
  },
});
