
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  emits: ['close'],
  props: {
    link: {
      type: String,
      required: true,
    },
    title: String,
    noclose: Boolean,
  },
  setup(props, { emit }) {
    const store = useStore();

    const isVisible = computed(() => store.state.modalstate[props.link]);
    const hideModal = () => {
      emit('close');
      store.dispatch('setModal', { link: props.link, value: false });
    };

    return {
      props,
      isVisible,
      hideModal,
    };
  },
});
