
import { defineComponent, ref } from 'vue';
// import SkillCircle from './SkillCircle.vue';

export default defineComponent({
  // components: { SkillCircle },
  props: {
    label: { type: String, default: 'right' },
  },
  setup() {
    const open = ref(false);

    const toggleOpen = () => {
      open.value = !open.value;
    };

    return {
      toggleOpen,
      open,
    };
  },
});
