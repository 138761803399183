import { App, Plugin, VNode, computed } from 'vue';
import store from '../store';

const checkperms = (el: HTMLElement, binding: any, vnode: VNode) => {
  if (!binding.modifiers.not) {
    if (Array.isArray(binding.value)) {
      let remove = true;
      for (const perm of binding.value) {
        if (store.getters.hasPerm(perm)) {
          remove = false;
        }
      }
      if (remove) {
        el.remove();
      }
    } else {
      if (!store.getters.hasPerm(binding.value)) {
        el.remove();
      }
    }
  } else {
    if (Array.isArray(binding.value)) {
      for (const perm of binding.value) {
        if (store.getters.hasPerm(perm)) {
          el.remove();
        }
      }
    } else {
      if (store.getters.hasPerm(binding.value)) {
        el.remove();
      }
    }
  }
};

export const hasperms: Plugin = {
  install: (app: App) => {
    app.directive('hasperm', checkperms);
    app.directive('hasperms', checkperms);
  },
};
