
import { defineComponent, computed, ref, onMounted, onUnmounted, nextTick, inject } from 'vue';

import Bar from './Bar.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';

import UserDropdown from './custom/UserDropdown.vue';
import { Emitter } from 'mitt';

type GradeResult = {
  target?: number;
  set?: number;
  value?: number;
  leader?: number;
  self?: number;
  history?: number;
  detailed?: {
    [propName: number]: number;
  };
};

export default defineComponent({
  props: {
    skills: { type: Object, required: true },
    type: { type: String, default: 'personal' },
    unit: { type: Number },
    title: { type: String, required: true },
    name: { type: String, default: '' },
  },
  components: {
    Bar,
    UserDropdown,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const sliceoffset = ref(0);
    const hovered = ref(-1);
    const barTableFilter = ref('avg');
    const barTable = ref<{ [propName: number]: GradeResult }>({});
    const table = ref<{ result: any; labels: any }>({ result: [], labels: [] });
    const header = ref();
    const container = ref();
    const fixed = ref(false);

    const loading = ref(true);

    const scrollhandler = () => {
      if (loading.value == false) {
        const headeroffset = (container.value as HTMLElement).offsetTop + 20;
        if (
          document.documentElement.scrollTop >= headeroffset &&
          document.documentElement.scrollTop <= headeroffset + (container.value as HTMLElement).clientHeight
        ) {
          fixed.value = true;
        } else {
          fixed.value = false;
        }
      }
    };

    // const fixed = computed(() => {
    //   if (fixed.value == true) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });

    const init = () => {
      switch (props.type) {
        case 'company':
          store.dispatch('fetchCompanyGrades').then((res) => {
            table.value = res;
            loading.value = false;
          });
          break;
        case 'unit':
          store.dispatch('fetchUnitGrades', props.unit).then((res) => {
            table.value = res;
            loading.value = false;
          });
          break;
        case 'personal':
          store.dispatch('fetchPersonalGrades', props.unit).then((res: any) => {
            barTable.value = res;
            loading.value = false;
          });
          break;
      }
    };

    onMounted(() => {
      store.dispatch('setCompareFilter', []);
      document.addEventListener('scroll', scrollhandler);
      //calcoffsets();
      init();
    });

    onUnmounted(() => {
      store.dispatch('setCompareFilter', []);
      document.removeEventListener('scroll', scrollhandler);
    });

    // watch(props, () => {
    //   init();
    // });
    const mitt = inject('emitter') as Emitter<{ awayfromdetails: string }>;
    const filteredBarTable = computed(() => {
      const table: { [propType: string]: { value: number; target: number; history: number } } = {};
      for (const index in barTable.value) {
        const item = barTable.value[index];

        switch (barTableFilter.value) {
          case 'avg':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.value || 0, target: item.target || 0, history: item.history || -1 };
            break;
          case 'manager':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.leader || 0, target: item.target || 0, history: item.history || -1 };
            break;
          case 'set':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.set || 0, target: item.target || 0, history: item.history || -1 };
            break;
          case 'self':
            mitt.emit('awayfromdetails', '');
            table[index] = { value: item.self || 0, target: item.target || 0, history: item.history || -1 };
            break;
          default:
            table[index] = {
              value: item.detailed ? item.detailed[parseInt(barTableFilter.value)] : 0,
              target: item.target || 0,
              history: item.history || -1,
            };
            break;
        }
      }

      return table;
    });

    const gradingusers = computed(() => {
      if (store.getters.hasPerm('user.all.manage') || store.getters.hasPerm('user.allbutceo.manage') || store.getters.hasPerm('user.unit.manage')) {
        const graders: Array<any> = [];
        for (const index in barTable.value) {
          const grade = barTable.value[index];
          if (grade.detailed) {
            for (const grader in grade.detailed) {
              if (grader != 'history') {
                if (!graders.includes(parseInt(grader))) graders.push(parseInt(grader));
              }
            }
          }
        }

        return graders;
      }
      return [];
    });

    const filterTable = (filter: string) => {
      if (filter == '-1') {
        barTableFilter.value = 'avg';
      } else {
        barTableFilter.value = filter;
      }
    };

    const matrixTable = computed(() => {
      return table.value.result;
    });
    const matrixLabels = computed(() => {
      const filter = store.getters.getCompareFilter;
      if (filter.length > 0) {
        return table.value.labels.filter((item: any) => filter.includes(item.id)) || [];
      } else {
        return table.value.labels || [];
      }
    });

    const slicedMatrixLabels = computed(() => matrixLabels.value?.slice(sliceoffset.value, sliceoffset.value + 6));

    const sumoffset = computed(() => {
      return 6 - (matrixLabels.value?.length || 0);
    });

    const left = () => {
      if (sliceoffset.value > 0) {
        sliceoffset.value--;
      }
    };
    const right = () => {
      if (sliceoffset.value < (matrixLabels.value?.length || 0) - 6) {
        sliceoffset.value++;
      }
    };

    const showScroller = computed(() => (matrixLabels.value?.length || 0) > 6);

    const onMouseOver = (id: number) => {
      hovered.value = id;
    };

    const onMouseLeave = () => {
      hovered.value = -1;
    };

    const navigate = (id: number) => {
      switch (props.type) {
        case 'company':
          store.dispatch('setCompareFilter', []);
          router.push({ name: 'OverviewUnit', params: { id } });
          break;
        case 'unit':
          store.dispatch('setCompareFilter', []);
          router.push({ name: 'OverviewPersonal', params: { id } });
      }
    };

    const showLegend = ref(false);
    const toggleLegend = () => {
      showLegend.value = !showLegend.value;
    };

    return {
      onMouseOver,
      onMouseLeave,
      hovered,
      filteredBarTable,
      matrixTable,
      slicedMatrixLabels,
      showScroller,
      mdiArrowLeft,
      mdiArrowRight,
      sumoffset,
      left,
      right,
      sliceoffset,
      navigate,
      filterTable,
      barTableFilter,
      table,
      header,
      fixed,
      container,
      loading,
      toggleLegend,
      showLegend,
      gradingusers,
    };
  },
});
