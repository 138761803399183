
import { defineComponent, computed, ref, Static, inject } from 'vue';
import { Emitter } from 'mitt';

export default defineComponent({
  props: {
    modelValue: Number,
    value: Number,
    locked: { type: Boolean, default: false },
    slices: { type: Number, default: -1 },
  },
  setup(props, { emit }) {
    const roundOff = (num: number, places: number) => {
      // if (!num) return undefined;
      const x = Math.pow(10, places);
      return Math.round(num * x) / x;
    };

    const model = computed({
      get: () => {
        let val = props.modelValue || props.value || 0;
        if (Number.isInteger(parseInt(val.toString())) && val >= 0 && val <= 1) {
          return val;
        } else if (Number.isInteger(parseInt(val.toString())) && val >= 1) {
          return 1;
        } else {
          return 0;
        }
      },
      set: (id: any) => emit('update:modelValue', id),
    });

    const open = ref(false);
    //c; //onst statik = ref(false);

    const onChange = (value: number) => {
      model.value = value;
      open.value = false;
    };

    const toggle = () => {
      if (!open.value) mitt.emit('closedropdowns', '');
      if (!statik.value) open.value = !open.value;
    };

    // const slicesize = roundOff(100 / props.slices, 2);

    const percent = computed(() => {
      if (props.slices == -1) return Math.round(model.value * 100);
      if (model.value <= 0.085) return 0;
      if (model.value <= 0.17 + 0.085) return 17;
      if (model.value <= 0.33 + 0.085) return 33;
      if (model.value <= 0.5 + 0.085) return 50;
      if (model.value <= 0.67 + 0.085) return 67;
      if (model.value <= 0.83 + 0.085) return 83;
      //if(model.value < )

      // for (let i = 0; i <= props.slices; i++) {
      //   console.log(slicesize, i, i * slicesize >= Math.round(model.value * 100));
      //   if (i * slicesize >= model.value * 100) {
      //     return Math.round(i * slicesize);
      //     // const diff = roundOff(i * slicesize - model.value * 100, 2);
      //     // console.log(diff);
      //     // if (diff >= slicesize / 2) {
      //     //   return Math.round((i - 1) * slicesize);
      //     // } else {
      //     //   return Math.round((i - 2) * slicesize);
      //     // }
      //   }
      //  }
      return 100;
    });

    const statik = computed(() => props.locked);
    const mitt = inject('emitter') as Emitter<{ closedropdowns: string }>;

    mitt.on('closedropdowns', () => {
      if (open.value) {
        open.value = false;
      }

      emit('closed');
    });

    return {
      statik,
      toggle,
      model,
      open,
      onChange,

      percent,
    };
  },
});
