
import { defineComponent } from 'vue';
import { mdiStar, mdiDiamond, mdiCrown } from '@mdi/js';

export default defineComponent({
  props: {
    skill: { type: Object, required: true },
    isFuture: { type: Boolean, default: false },
    isStrategic: { type: Boolean, default: false },
  },
  setup() {
    return {
      mdiStar,
      mdiDiamond,
      mdiCrown,
    };
  },
});
