import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c7a6c484"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["smdtooltip", { active: _ctx.active, text: _ctx.text, small: _ctx.small }]),
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onActivate && _ctx.onActivate(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDeactivate && _ctx.onDeactivate(...args)))
  }, [
    (!_ctx.text && !_ctx.blank)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: "icon",
          path: _ctx.mdiInformationOutline,
          width: 25,
          height: 25
        }, null, 8, ["path"]))
      : _createCommentVNode("", true),
    (_ctx.text && !_ctx.blank)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "ttfade" }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["content", _ctx.position])
            }, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 34))
}