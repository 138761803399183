import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "icons" }
const _hoisted_2 = {
  key: 1,
  class: "text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["skill", { strategic: _ctx.isStrategic, future: _ctx.isFuture, leadership: _ctx.skill.leadership }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.isStrategic)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            path: _ctx.mdiStar,
            width: 30,
            height: 30,
            class: "strategic"
          }, null, 8, ["path"]))
        : _createCommentVNode("", true),
      (_ctx.isFuture)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            path: _ctx.mdiDiamond,
            width: 30,
            height: 30,
            class: "future"
          }, null, 8, ["path"]))
        : _createCommentVNode("", true),
      (_ctx.skill.leadership)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 2,
            path: _ctx.mdiCrown,
            width: 30,
            height: 30,
            class: "leadership"
          }, null, 8, ["path"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.skill.description && _ctx.skill.description != '')
      ? (_openBlock(), _createBlock(_component_Tooltip, {
          key: 0,
          text: _ctx.skill.name.toUpperCase()
        }, {
          default: _withCtx(() => [
            _createElementVNode("p", null, [
              _createElementVNode("b", null, _toDisplayString(_ctx.skill.name), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.skill.description), 1)
          ]),
          _: 1
        }, 8, ["text"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.skill.name), 1)
        ]))
  ], 2))
}