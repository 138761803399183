import { AxiosResponse } from 'axios';
import { set } from './_util';

export type Skill = {
  id: number;
  name: string;
  description: string;
  group: 'personal' | 'social' | 'working';
  type: 1 | 2 | 3;
  strategic: Array<number>;
  future: Array<number>;
  leadership: boolean;
};

const skills = {
  state: {
    skills: [],
    unitGrades: {},
    personalGrades: {},
    companyGrades: {},
    overviewGrades: {},
  },
  mutations: {
    setSkills: set('skills'),
    setUnitGrades: set('unitGrades'),
    setPersonalGrades: set('personalGrades'),
    setCompanyGrades: set('companyGrades'),
    setOverviewGrades: set('overviewGrades'),
  },
  actions: {
    fetchSkills(context: any, force?: boolean) {
      // if (context.state.skills.length <= 0 || force) {
      return context.dispatch('API', { method: 'get', url: 'skills' }).then((res: AxiosResponse) => {
        context.commit('setSkills', res.data.message);
        return Promise.resolve(res.data.message);
      });
      // } else {
      //   return context.state.skills;
      // }
    },
    fetchMeta(context: any) {
      return context.dispatch('API', { method: 'get', url: 'grades/meta' }).then((res: AxiosResponse) => {
        return Promise.resolve(res.data.message);
      });
    },
    fetchSkillsForUnit(context: any, id: number) {
      // return context.dispatch('fetchSkills').then((res: any) => {
      //   console.log(res);
      //   return res;
      // });
      return context.dispatch('API', { method: 'get', url: `skills/unit/${id}` }).then((res: AxiosResponse) => {
        context.commit('setSkills', res.data.message);
        return Promise.resolve(res.data.message);
      });
    },
    fetchUnitGrades(context: any, id: number) {
      return context.dispatch('API', { method: 'get', url: `grades/unit/${id}` }).then((res: AxiosResponse) => {
        context.commit('setUnitGrades', res.data.message);
        return res.data.message;
      });
    },
    fetchCompanyGrades(context: any) {
      return context.dispatch('API', { method: 'get', url: 'grades/company' }).then((res: AxiosResponse) => {
        context.commit('setCompanyGrades', res.data.message);
        return res.data.message;
      });
    },
    fetchOverviewGrades(context: any, id: number) {
      if (id) {
        return context.dispatch('API', { method: 'get', url: `grades/overview/${id}` }).then((res: AxiosResponse) => {
          context.commit('setOverviewGrades', res.data.message);
          return Promise.resolve(res.data.message);
        });
      } else {
        return context.dispatch('API', { method: 'get', url: `grades/overview` }).then((res: AxiosResponse) => {
          context.commit('setOverviewGrades', res.data.message);
          return Promise.resolve(res.data.message);
        });
      }
    },
    fetchPersonalGrades(context: any, id: number) {
      return context.dispatch('API', { method: 'get', url: `grades/user/${id}` }).then((res: AxiosResponse) => {
        context.commit('setPersonalGrades', res.data.message);
        return Promise.resolve(res.data.message);
      });
    },
  },
  getters: {
    getSkillGroup(state: any) {
      return (group: 'personal' | 'social' | 'working') => state.skills.filter((skill: Skill) => skill.group == group);
    },
    getSkills(state: any) {
      return state.skills.sort((a: Skill, b: Skill) => {
        if (a.strategic && b.strategic) return 0;
        if (a.strategic && !b.strategic) return -1;
        if (!a.strategic && b.strategic) return 1;
        return 0;
      });
    },
    getSort(state: any) {
      return (unit_id?: number) => (a: Skill, b: Skill) => {
        let weighta = 0;
        let weightb = 0;

        if (a.leadership) weighta = 10000;
        if (b.leadership) weightb = 10000;

        if ((unit_id && a.future.includes(unit_id)) || a.future.length > 0) weighta += 1000;
        if ((unit_id && b.future.includes(unit_id)) || b.future.length > 0) weightb += 1000;

        if ((unit_id && a.strategic.includes(unit_id)) || a.strategic.length > 0) weighta += 100;
        if ((unit_id && b.strategic.includes(unit_id)) || b.strategic.length > 0) weightb += 100;

        if (a.name < b.name) weighta += 10;
        if (a.name > b.name) weightb += 10;

        return weightb - weighta;
      };
    },
    getTypes(state: any) {
      return [
        { type: 'personal', name: 'Persönliche Skills' },
        { type: 'social', name: 'Soziale Skills' },
        { type: 'working', name: 'Fachliche Skills' },
      ];
    },
    getWorkingSkills(state: any) {
      return state.skills
        .filter((skill: Skill) => skill.group == 'working')
        .sort((a: Skill, b: Skill) => {
          if (a.strategic && b.strategic) return 0;
          if (a.strategic && !b.strategic) return -1;
          if (!a.strategic && b.strategic) return 1;
          return 0;
        });
    },
    getSocialSkills(state: any) {
      return state.skills
        .filter((skill: Skill) => skill.group == 'social')
        .sort((a: Skill, b: Skill) => {
          if (a.strategic && b.strategic) return 0;
          if (a.strategic && !b.strategic) return -1;
          if (!a.strategic && b.strategic) return 1;
          return 0;
        });
    },
    getPersonalSkills(state: any) {
      return state.skills
        .filter((skill: Skill) => skill.group == 'personal')
        .sort((a: Skill, b: Skill) => {
          if (a.strategic && b.strategic) return 0;
          if (a.strategic && !b.strategic) return -1;
          if (!a.strategic && b.strategic) return 1;
          return 0;
        });
    },
    getRating(state: any) {
      return (value: number) => {
        if (value < 0) return 'nothing';
        if (value <= 0.5) return 'low';
        if (value <= 0.66) return 'medium';
        return 'high';
      };
    },
    getUnitTable(state: any) {
      return state.unitGrades;
    },
    getCompanyTable(state: any) {
      return state.companyGrades;
    },
    getPersonalTable(state: any) {
      return state.personalGrades;
    },
    getOverviewSkills(state: any) {
      return state.overviewGrades;
    },
  },
};

export default skills;
