
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';
import DegreeBar from '@/components/DegreeBar.vue';

export default defineComponent({
  components: {
    DegreeBar,
  },
  props: {
    h1: { type: String },
    h2: { type: String },
    feedback: { type: Boolean, default: true },
  },
  setup() {
    const store = useStore();

    const showbuttons = computed(() => {
      if (store.getters.hasPerm('overview.manager')) {
        return true;
      }

      return false;
    });

    return {
      showbuttons,
    };
  },
});
