
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    value: { type: Number, required: true },
    target: { type: Number, required: true },
    history: { type: Number, default: -1 },
  },
  setup(props) {
    const store = useStore();
    const clazz = (id: number) => store.getters.getRating(id);

    const getOffset = (value: number) => 100 * value;

    return {
      getOffset,
      clazz,
    };
  },
});
