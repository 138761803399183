import { renderSlot as _renderSlot, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    class: ["modal-button", { open: _ctx.isVisible }]
  }, _ctx.props, {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showModal && _ctx.showModal(...args)))
  }), [
    _renderSlot(_ctx.$slots, "default")
  ], 16))
}