import { set } from './_util';

const company = {
  state: {
    companyName: 'STYRIA HUMAN RESSOURCES',
    workers: [],
    units: [],
  },
  mutations: {
    setWorkers: set('workers'),
    setUnits: set('units'),
  },
  actions: {
    fetchWorkers(context: any) {
      return context.dispatch('API', { url: 'users' }).then((res: any) => {
        context.commit('setWorkers', res.data.message);
        return res.data.message;
      });
    },
    fetchAllWorkers(context: any) {
      return context.dispatch('API', { url: 'users/all' }).then((res: any) => {
        context.commit('setWorkers', res.data.message);
        return res.data.message;
      });
    },
    fetchManagers(context: any, id: number) {
      return context.dispatch('API', { url: `users/managers/${id}` }).then((res: any) => {
        return res.data.message;
      });
    },
    fetchUserData(context: any, id: number) {
      return context.dispatch('API', { url: `users/${id}`, method: 'get' }).then((res: any) => res.data.message);
    },
    fetchUnits(context: any) {
      return context.dispatch('API', { url: `units`, method: 'get' }).then((res: any) => {
        context.commit('setUnits', res.data.message);
        return Promise.resolve(res.data.message);
      });
    },
    updateWorker(context: any, payload: { id: number; user: any }) {
      return context.dispatch('API', { url: `users/${payload.id}`, method: 'patch', data: payload.user }).then((res: any) => {
        //context.dispatch('addNotification', { message: `User ${res.data.message[0].id} angelegt` });
        return res.data.message;
      });
    },
    saveNotiz(context: any, payload: { id: number; notiz: string }) {
      return context.dispatch('API', { url: `users/${payload.id}/notiz`, method: 'patch', data: { notiz: payload.notiz } }).then((res: any) => {
        return res.data.message;
      });
    },
    fetchNotiz(context: any, id: number) {
      return context.dispatch('API', { url: `users/${id}/notiz`, method: 'get' }).then((res: any) => res.data.message);
    },
    insertWorker(context: any, payload: { user: any }) {
      return context.dispatch('API', { url: `users`, method: 'post', data: payload.user }).then((res: any) => {
        //context.dispatch('addNotification', { message: `User ${res.data.message[0].id} angelegt` });
        return res.data.message;
      });
    },
    removeWorker(context: any, id: number) {
      return context.dispatch('API', { method: 'delete', url: `users/${id}` });
    },
    saveFeedbacker(context: any, payload: { id: number; data: Array<{ value: number; label: string }> }) {
      return context.dispatch('API', { method: 'post', url: `feedback/${payload.id}`, data: payload.data });
    },
  },
  getters: {
    getCompanyName(state: any) {
      return state.companyName;
    },
    getWorkers(state: any) {
      return state.workers;
    },
    getUnit(state: any) {
      return (id: number) => state.units.find((unit: any) => unit.id == id);
    },
    getUnits(state: any) {
      return state.units;
    },
  },
};

export default company;
