
import { defineComponent, computed, ref, PropType } from 'vue';
import { mdiMenuDown } from '@mdi/js';

export default defineComponent({
  props: {
    options: { type: Array as PropType<Array<{ label: string; value: any }>>, required: true },
    label: { type: String, default: 'true' },
    modelValue: [String, Number],
    twoline: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (id: any) => emit('update:modelValue', id),
    });
    const onChange = (value: any) => {
      model.value = value;
      open.value = false;
    };
    const selected = computed(() => props.options.find((item: any) => item.value == model.value) || { label: props.label });
    const open = ref(false);
    const toggleOpen = () => {
      open.value = !open.value;
    };
    const onBlur = () => (open.value = false);
    return {
      model,
      selected,
      onChange,
      open,
      toggleOpen,
      onBlur,
      mdiMenuDown,
    };
  },
});
